import { useEffect } from 'react';

import localFont from '@next/font/local';
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { defaultTheme } from '@hultafors/hellberg/helpers';
import { PageProps } from '@hultafors/hellberg/types';

import i18n from '../next.i18n';

export { reportWebVitals } from '@hultafors/shared/helpers';

const ApplicationProvider = dynamic(() =>
  import('@hultafors/hellberg/context').then((mod) => mod.ApplicationProvider),
);
const SharedThemeProvider = dynamic(() =>
  import('@hultafors/shared/context').then((mod) => mod.SharedThemeProvider),
);
const MaintenanceBlock = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.MaintenanceBlock),
);
const TimedDialog = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.TimedDialog),
);
const Banner = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Banner),
);
const Footer = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Footer),
);
const GlobalStyle = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.GlobalStyle),
);
const Nav = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Nav),
);

const fontRegular = localFont({
  src: '../fonts/NeueHaasGroteskText.woff2',
  variable: '--font-regular',
});

const fontRegularBold = localFont({
  src: '../fonts/NHaasGroteskTXW01-65Md.woff2',
  variable: '--font-bold',
});

const fontHero = localFont({
  src: '../fonts/NeueHaasGroteskDispW05_65M.woff2',
  variable: '--font-hero',
});

const CustomApp = ({ Component, pageProps, router }: AppProps<PageProps>) => {
  const {
    shared,
    siteLocale,
    allNavItems,
    footer,
    maintenanceMode,
    timedDialog,
    featureToggle,
  } = pageProps;

  const onRouteChangeComplete = (page: string) => {
    sendGTMEvent({ event: 'pageview', page });
  };

  useEffect(() => {
    if (router.events) {
      onRouteChangeComplete('/');
      router.events.on('routeChangeComplete', onRouteChangeComplete);
    }
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const fonts = [
    fontRegular.variable,
    fontRegularBold.variable,
    fontHero.variable,
  ].join(' ');

  const i18nConfig = i18n();

  return (
    <>
      <style jsx global>
        {`
          html,
          input,
          textarea,
          button,
          select {
            font-family: ${fontRegular.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <SharedThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <ApplicationProvider
          value={{
            allNavItems,
            featureToggle,
            footer,
            shared,
            siteLocale,
          }}
          i18nConfig={i18nConfig}
        >
          <div className={fonts}>
            <Nav />
            {maintenanceMode?.enabled && (
              <MaintenanceBlock data={maintenanceMode} />
            )}
            <Component {...pageProps} />
            <GoogleTagManager gtmId="GTM-WF44LKZ" />
            <Banner />
            <Footer />
            {timedDialog?.formUrl && (
              <TimedDialog timedDialogData={timedDialog} />
            )}
            <div id="portal"></div>
          </div>
        </ApplicationProvider>
      </SharedThemeProvider>
    </>
  );
};

export default CustomApp;
